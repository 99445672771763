<template>
  <div>
    <!-- <v-btn
      v-if="$admin.can('locations.create')"
      color="primary"
      dark
      @click="openModal"
    >
      {{ $t("add_new") }}
    </v-btn> -->
    <!-- v-if="isVisibleDialog" -->
    <v-dialog
      v-model="isVisibleDialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          @click="openModal"
        >
          {{ $t("add_new") }}
        </v-btn>
      </template>

      <driving-staff-form
        v-if="isVisibleDialog"
        type="create"
        :loading="isLoading"
        @onsave="saveItem"
        @onclose="closeForm"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    DrivingStaffForm: () => import("../utils/DrivingStaffForm.vue"),
  },
  props: {
    filterOptions: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isLoading: false,
      isVisibleDialog: false,
    };
  },

  computed: {
    ...mapGetters({
      authUser: "getUser",
    }),
  },

  watch: {
    // add any required watchers here
  },

  // async mounted() {
  // if (this.authUser.isAdmin) {
  // await this.$store.dispatch("meta/getClients", "tenant");
  // }
  // },

  methods: {
    openModal() {
      this.isVisibleDialog = !this.isVisibleDialog;
    },
    closeForm() {
      // this.$refs.form.reset();
      this.isVisibleDialog = false;
    },
    async saveItem(payload) {
      this.isLoading = true;
      payload.tenant_id = this.filterOptions.tenant_id;
      let params = { payload, filter: { ...this.filterOptions } };
      await this.$store
        .dispatch("drivingStaff/add", params)
        .then(() => {
          this.$store.dispatch(
            "alerts/success",
            this.$t("created_successfully")
          );
          this.closeForm();
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
